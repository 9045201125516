import { Button, Select } from "antd";
import { debounce, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import axiosConfig from "../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../utils/config";
import { Toast } from "../../../reusable/Toast";

const { Option } = Select;

function FilterColumn({
  setUserId,
  setTipeTransaksi,
  setPaymentMethodId,
  setItemId,
  setItemType,
  setExport,
  options,
  type,
}) {
  const [users, setUsers] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transactionType, setTransactionType] = useState("");

  const onSearchUsers = debounce(async (value) => {
    try {
      setLoading(true);
      let response = await axiosConfig.get(`${API_ENDPOINT}/users`, {
        params: { username: value },
      });
      setUsers(response.data.data.users);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, 500);

  const onSearchPayments = debounce(async (value) => {
    try {
      setLoading(true);
      let response = await axiosConfig.get(`${API_ENDPOINT}/payment-methods/${type}`, {
        params: { code: value, take: 40 },
      });
      setPaymentMethods(response.data.data.payment_methods);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, 500);

  const onSearchItems = debounce(async (value) => {
    try {
      setLoading(true);
      let response = await axiosConfig.get(`${API_ENDPOINT}/items`, {
        params: { keyword: value, take: 10 },
      });
      setItems(response.data.data.items);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, 500);

  useEffect(() => {
    onSearchUsers();
    onSearchPayments();
    onSearchItems();
  }, []);

  return (
    <div className="flex justify-between">
      <div className="ml-0">
        <Button
          type="primary"
          shape="default"
          size={"middle"}
          onClick={() => setExport(true)}
        >
          Export
        </Button>
      </div>
      <div className="flex gap-4 items-center">
        <Select
          className="w-52 font-normal"
          showSearch
          loading={loading}
          onChange={(value) => setItemId(value)}
          onSearch={onSearchItems}
          notFoundContent={
            <div className="flex justify-center items-center">
              Item tidak ditemukan
            </div>
          }
          filterOption={false}
          placeholder="Filter Nama Item"
        >
          <Option value={null}>Semua</Option>
          {items.map((item) => (
            <Option key={item.id} value={item.id}>
              {item.nama}
            </Option>
          ))}
        </Select>
        <Select
          className="w-52 font-normal"
          onChange={(value) => setPaymentMethodId(value)}
          showSearch
          onSearch={onSearchPayments}
          loading={loading}
          notFoundContent={
            <div className="flex justify-center items-center">
              Pembayaran Tidak ditemukan
            </div>
          }
          filterOption={false}
          placeholder="Cari Payment Method"
        >
          <Option value={null}>Semua</Option>
          {paymentMethods.map((paymentMethod) => (
            <Option key={paymentMethod.id} value={paymentMethod.id}>
              {paymentMethod.nama}
            </Option>
          ))}
        </Select>
        <Select
          className="w-52 font-normal"
          onChange={(value) => {
            setTipeTransaksi(value);
            setTransactionType(value);
          }}
          notFoundContent={
            <div className="flex justify-center items-center">
              Tipe Transaksi tidak ditemukan
            </div>
          }
          placeholder="Tipe Transaksi"
        >
          <Option value={""}>Semua</Option>
          {options.map((option) => (
            <Option value={option.key}>{option.value}</Option>
          ))}
        </Select>
        <Select
          className="w-52 font-normal"
          onChange={(value) => setUserId(value)}
          showSearch
          onSearch={onSearchUsers}
          loading={loading}
          notFoundContent={
            <div className="flex justify-center items-center">
              User Tidak ditemukan
            </div>
          }
          filterOption={false}
          placeholder="Cari Username Pengguna"
        >
          {!isEmpty(users) && <Option disabled>Pilih Username</Option>}
          <Option value={null}>Semua</Option>
          {users.map((user) => (
            <Option key={user.id} value={user.id}>
              {user.username}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default FilterColumn;
