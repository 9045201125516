import React from "react";
import numeral from "../../../../utils/numeral";

function Saldo({ data, name, desc, color, count = false }) {
  return (
    <div className="grid grid-cols-2 px-4 py-8 gap-4">
      <div className="justify-self-start">
        <h3 className="font-bold text-sm">{name}</h3>
        <h3 className="text-xs mt-2">{desc}</h3>
      </div>
      <>
        {color == "orange" ? (
          <>
            <div
              className={`font-bold text-2xl text-orange-600 justify-self-end`}
            >
              {count
                ? numeral(data).format("0,0")
                : "Rp" + numeral(data).format("0,0")}
            </div>
            <div
              className={`col-span-2 border-2 border-orange-600 rounded-full self-end`}
            ></div>
          </>
        ) : null}

        {color == "yellow" ? (
          <>
            <div
              className={`font-bold text-2xl text-yellow-400 justify-self-end`}
            >
              {count
                ? numeral(data).format("0,0")
                : "Rp" + numeral(data).format("0,0")}
            </div>
            <div
              className={`col-span-2 border-2 border-yellow-400 rounded-full self-end`}
            ></div>
          </>
        ) : null}

        {color == "cyan" ? (
          <>
            <div
              className={`font-bold text-2xl text-cyan-400 justify-self-end`}
            >
              {count
                ? numeral(data).format("0,0")
                : "Rp" + numeral(data).format("0,0")}
            </div>
            <div
              className={`col-span-2 border-2 border-cyan-400 rounded-full self-end`}
            ></div>
          </>
        ) : null}

        {color == "purple" ? (
          <>
            <div
              className={`font-bold text-2xl text-purple-600 justify-self-end`}
            >
              {count
                ? numeral(data).format("0,0")
                : "Rp" + numeral(data).format("0,0")}
            </div>
            <div
              className={`col-span-2 border-2 border-purple-600 rounded-full self-end`}
            ></div>
          </>
        ) : null}

        {color != "orange" &&
        color != "yellow" &&
        color != "purple" &&
        color != "cyan" ? (
          <>
            <div
              className={`font-bold text-2xl text-${color}-600 justify-self-end`}
            >
              {count
                ? numeral(data).format("0,0")
                : "Rp" + numeral(data).format("0,0")}
            </div>
            <div
              className={`col-span-2 border-2 border-${color}-600 rounded-full self-end`}
            ></div>
          </>
        ) : null}
      </>
    </div>
  );
}

export default Saldo;
