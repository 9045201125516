import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../../layout/Layout";
import moment from "moment";
import { Button, DatePicker, Input, Select, Spin, Table, Tabs } from "antd";
import numeral from "numeral";
import {
  AreaChartOutlined,
  ControlOutlined,
  EyeFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import { setLoading } from "../../../store/loading";
import axiosConfig from "../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../utils/config";
import { isEmpty } from "lodash";
import { Toast } from "../../../reusable/Toast";
import Saldo from "./_partials/Saldo";
import TabPane from "antd/lib/tabs/TabPane";
import TableCustomer from "./_partials/TableCustomer";

const { Search } = Input;
const { RangePicker } = DatePicker;

const initialPagination = {
  current: 1,
  pageSize: 10,
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const OperationsSlot = {
  left: (
    <div className="flex gap-2">
      <AreaChartOutlined
        className="my-auto text-blue-600"
        style={{ fontSize: "16px" }}
      />
      <div className=" border-gray-300 font-bold text-gray-custom text-lg">
        Transaction Customer
      </div>
    </div>
  ),
};

function TransactionCustomer() {
  const [date, setDate] = useState({
    date_from: moment().subtract(1, "week"),
    date_to: moment(),
  });
  const [search, setSearch] = useState("");
  const [paginationState, setPaginationState] = useState(initialPagination);
  const [all, setAll] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [model, setModel] = useState("");
  const onChangeDate = (date, dateStrings) => {
    setDate({
      date_from: date[0],
      date_to: date[1],
    });
  };
  const disabledDate = (current) => {
    return current && current > moment();
  };

  const onChangeTab = (key) => {
    console.log(key);
    setModel(key);
  };

  const items = ({ date }) => {
    return [
      {
        key: "all",
        label: <p className="font-semibold">All Transaksi</p>,
        children: <TableCustomer model="all" date={date} />,
      },
      {
        key: "berlangganan",
        label: <p className="font-semibold">Berlangganan</p>,
        children: <TableCustomer model="berlangganan" date={date} />,
      },
      {
        key: "marketplace",
        label: <p className="font-semibold">Marketplace</p>,
        children: <TableCustomer model="marketplace" date={date} />,
      },
      {
        key: "event",
        label: <p className="font-semibold">Event</p>,
        children: <TableCustomer model="event" date={date} />,
      },
      {
        key: "pembayaran",
        label: <p className="font-semibold">Pembayaran</p>,
        children: <TableCustomer model="pembayaran" date={date} />,
      },
      {
        key: "support",
        label: <p className="font-semibold">Support</p>,
        children: <TableCustomer model="support" date={date} />,
      },
      {
        key: "donasi",
        label: <p className="font-semibold">Donasi</p>,
        children: <TableCustomer model="donasi" date={date} />,
      },
    ];
  };

  const handleTableChange = (pagination, filter, sorter) => {
    console.log(sorter);
    if (isEmpty(sorter)) {
      fetchCustomerDatatable(pagination, {});
    } else {
      fetchCustomerDatatable(pagination, {
        field: sorter.field,
        sort: sorter.order,
      });
    }
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const operations = (
    <div className="flex gap-2">
      <AreaChartOutlined
        className="my-auto text-blue-600"
        style={{ fontSize: "16px" }}
      />
      <div className=" border-gray-300 font-bold text-gray-custom text-lg">
        Transaction Customer
      </div>
    </div>
  );

  const [position, setPosition] = useState(["left", "right"]);
  const slot = useMemo(() => {
    if (position.length === 0) return null;
    return position.reduce(
      (acc, direction) => ({
        ...acc,
        [direction]: OperationsSlot[direction],
      }),
      {}
    );
  }, [position]);

  const fetchCustomerDatatable = async (pagination, sorter) => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `${API_ENDPOINT}/customer/transaction`,
        {
          params: {
            page: pagination.current,
            limit: pagination.pageSize,
            date_from: date.date_from.format("YYYY-MM-DD"),
            date_to: date.date_to.format("YYYY-MM-DD"),
            search,
            ...sorter,
          },
        }
      );
      setData(response.data.data);
      setPaginationState({
        current: response.data.data.current_page,
        pageSize: response.data.data.per_page,
        total: response.data.data.total,
      });
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title:
          error.response.data?.info ??
          "Terjadi suatu error saat pengambilan data",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `${API_ENDPOINT}/customer/transaction/export`,
        {
          params: {
            date_from: date.date_from.format("YYYY-MM-DD"),
            date_to: date.date_to.format("YYYY-MM-DD"),
            model: model,
          },
        }
      );
      const link = document.createElement("a");
      link.href = response.data.data;
      link.download = "Riwayat Penarikan Berbagilink.xlsx";
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title:
          error.response.data?.info ??
          "Terjadi suatu error saat pengambilan data",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerDatatable(paginationState);
  }, [search, date]);

  return (
    <Layout>
      <header className="py-3 px-6 border-t border-gray-300 bg-white w-full flex justify-between items-center">
        <div className="flex gap-3 items-center">
          <div>Transaction Customer</div>
          <div className="border-r h-4 border-gray-400"></div>
          <div className="text-gray-400">Berbagi.link</div>
        </div>
        <div className="flex gap-4 items-center">
          <Button className="rounded-sm" type="primary" onClick={handleExport}>
            Export
          </Button>
          <RangePicker
            onChange={onChangeDate}
            disabledDate={disabledDate}
            defaultValue={[moment().subtract(1, "week"), moment()]}
            ranges={{
              "Hari Ini": [moment(), moment()],
              Kemarin: [moment().subtract(1, "days"), moment()],
              "Minggu ini": [moment().subtract(1, "week"), moment()],
              "Minggu lalu": [moment().subtract(2, "weeks"), moment()],
              "Bulan ini": [moment().subtract(1, "month"), moment()],
              "Bulan lalu": [
                moment().subtract(2, "months"),
                moment().subtract(1, "month"),
              ],
              "Tahun Ini": [moment().subtract(1, "year"), moment()],
            }}
            format="DD-MM-YYYY"
          />
        </div>
      </header>
      <div className="m-2 grid grid-cols-3 gap-3">
        <div className="bg-white relative">
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
              <Spin indicator={loadingIcon} />
            </div>
          )}
          <Saldo
            data={data.event}
            name={"Total Event"}
            desc={"Nominal Transaksi yang diperoleh dari Event"}
            color="blue"
          />
        </div>
        <div className="bg-white relative">
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
              <Spin indicator={loadingIcon} />
            </div>
          )}
          <Saldo
            data={data.marketplace}
            name={"Total Marketplace"}
            desc={"Nominal Transaksi yang diperoleh dari Marketplace"}
            color="green"
          />
        </div>
        <div className="bg-white relative">
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
              <Spin indicator={loadingIcon} />
            </div>
          )}
          <Saldo
            data={data.pembayaran}
            name={"Total Pembayaran"}
            desc={"Nominal Transaksi yang diperoleh dari Pembayaran"}
            color="orange"
          />
        </div>
        <div className="bg-white relative">
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
              <Spin indicator={loadingIcon} />
            </div>
          )}
          <Saldo
            data={data.donasi}
            name={"Total Donasi"}
            desc={"Nominal Transaksi yang diperoleh dari Donasi"}
            color="yellow"
          />
        </div>
        <div className="bg-white relative">
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
              <Spin indicator={loadingIcon} />
            </div>
          )}
          <Saldo
            data={data.berlangganan}
            name={"Total Berlangganan"}
            desc={"Nominal Transaksi yang diperoleh dari Berlangganan"}
            color={"purple"}
          />
        </div>
        <div className="bg-white relative">
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
              <Spin indicator={loadingIcon} />
            </div>
          )}
          <Saldo
            data={data.support}
            name={"Total Support"}
            desc={"Nominal Transaksi yang diperoleh dari Support"}
            color={"cyan"}
          />
        </div>
      </div>
      <div className="bg-white grid rounded-lg m-2">
        <Tabs
          defaultActiveKey="all"
          tabBarExtraContent={slot}
          centered
          items={items({ date })}
          onChange={onChangeTab}
          className="px-3"
        />
        {/* <div className="py-2 px-4 border-b-2 flex justify-between">
          <div className="flex gap-2">
            <AreaChartOutlined
              className="my-auto text-blue-600"
              style={{ fontSize: "16px" }}
            />
            <div className=" border-gray-300 font-bold text-gray-custom text-lg">
              Transaction Customer
            </div>
          </div>
          <div className="flex"></div>
        </div> */}
        {/* <div className="py-3 px-4 overflow-x-scroll">
          <div className="flex items-end justify-end mt-3 mb-3">
            <Search
              placeholder="Masukkan pencarian"
              className="max-w-xxs"
              allowClear
              enterButton="Cari"
              onSearch={onSearch}
            />
          </div>
          <Table
            columns={columns}
            dataSource={customer}
            pagination={paginationState}
            loading={loading}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
          />
        </div> */}
      </div>
    </Layout>
  );
}

export default TransactionCustomer;
