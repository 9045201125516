import { Modal } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import numeral from '../../../../utils/numeral';

function DetailModal({ modal, setModal }) {
	const onClose = () => {
		setModal({});
	};

	return (
		<Modal onCancel={onClose} open={!isEmpty(modal)} title="Terima Ajuan Penarikan" footer={[]} destroyOnClose>
			{!isEmpty(modal) && (
				<div className="grid grid-cols-2 gap-y-2 items-center">
					<p className="font-bold text-lg">Username:</p>
					<p className="text-lg justify-self-end">{modal.user.username}</p>
					<p className="font-bold text-lg">Bank:</p>
					<p className="text-lg justify-self-end">{modal.bank}</p>
					<p className="font-bold text-lg">Nama Pemilik Rekening:</p>
					<p className="text-lg justify-self-end">{modal.nama_pemilik_rekening}</p>
					<p className="font-bold text-lg">Nomor Rekening:</p>
					<p className="text-lg justify-self-end">{modal.nomor_rekening}</p>
					<p className="font-bold text-lg">Nominal:</p>
					<p className="text-lg justify-self-end">Rp. {numeral(modal.nominal).format('0,0')}</p>
					<p className="font-bold text-lg">Biaya Admin:</p>
					<p className="text-lg justify-self-end">Rp. {numeral(modal.admin_fee).format('0,0')}</p>
					<p className="font-bold text-lg">Total:</p>
					<p className="text-lg justify-self-end">Rp. {numeral(modal.total).format('0,0')}</p>
					<p className="font-bold text-lg">Tanggal Pengajuan:</p>
					<p className="text-lg justify-self-end">{modal.created_at}</p>
					<p className="font-bold text-lg">Status:</p>
					<p className="text-lg justify-self-end bg-blue-500 text-white px-4 font-bold rounded-lg">{modal.status}</p>
				</div>
			)}
		</Modal>
	);
}

export default DetailModal;
