import { Switch, Table } from 'antd';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Layout from '../../../layout/Layout';
import { Toast } from '../../../reusable/Toast';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import FilterColumn from './FilterColumn';
import EditModal from './EditModal';
import numeral from '../../../utils/numeral'

const initialPagination = {
	current: 1,
	pageSize: 10,
};

function PaymentMethod() {
	const [paginationState, setPaginationState] = useState(initialPagination);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [tipeTransaksi, setTipeTransaksi] = useState(null);
	const [paymentMethodName, setPaymentMethodName] = useState(null);
	const [typeOfPayment, setTypeOfPayment] = useState(null);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [modal, setModal] = useState({});
	const [errors, setErrors] = useState({});

	const onChange = async (value, id) => {
		setLoading(true);
		try {
			const response = await axiosConfig.post(`${API_ENDPOINT}/payment-methods/event-and-onlinestore/${id}/status`);
			fetchRequestPaymentMethod(paginationState);
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data?.info ?? 'Terjadi suatu error saat mengganti status',
			});
		} finally {
			setLoading(false);
		}
	};

	const onUpdateData = async () => {
		if (modal && modal.log) {
			setLoading(true);
			setErrors({});
			try {
				let _values = modal.log;
				const response = await axiosConfig.post(`${API_ENDPOINT}/payment-methods/event-and-onlinestore/${modal.log.id}/update`, {
					is_active: _values.is_active ? 1 : 0,
					type_admin_fee_berbagilink: _values.type_admin_fee_berbagilink,
					admin_fee_berbagilink: _values.admin_fee_berbagilink
				});
				Toast.fire({
					icon: 'success',
					title: response.data?.info ?? 'Terjadi suatu error saat mengganti status',
				});
				fetchRequestPaymentMethod(paginationState);
			} catch (error) {
				console.log(error);
				if (error.response && error.response.data && error.response.data.data && error.response.data.data.errors) {
					setErrors({...error.response.data.data.errors});
				}
				Toast.fire({
					icon: 'error',
					title: error.response.data?.info ?? 'Terjadi suatu error saat mengganti status',
				});
			} finally {
				setLoading(false);
			}
		};
	}

	const columns = setModal => {
		const onEditModal = log => {
			let _log = {...log};
			setModal({
				type: 'edit',
				log: _log,
			});
		};
		
		return [
			{
				title: 'ID',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'Gambar',
				dataIndex: 'img',
				key: 'img',
				render: (text, record) => <img className="w-32" src={record.img} alt="" />,
			},
			{
				title: 'Nama',
				dataIndex: 'nama',
				key: 'nama',
			},
			{
				title: 'Tipe Pembayaran',
				dataIndex: 'type_of_payment',
				key: 'type_of_payment',
				render: (text, record) => {
					if (record.type_of_payment === 'manual') {
						return 'Manual';
					} else {
						return 'Otomatis';
					}
				},
			},
			{
				title: 'Jenis Pembayaran',
				dataIndex: 'payment_method',
				key: 'payment_method',
				render: (text, record) => {
					if (record.payment_method === 'bank') {
						return 'Bank Transfer';
					} else if (record.payment_method === 'va') {
						return 'Virtual Account';
					} else if (record.payment_method === 'qris') {
						return 'QRIS';
					} else if (record.payment_method === 'e-wallet') {
						return 'E-Wallet';
					} else if (record.payment_method === 'retail') {
						return 'Gerai';
					} else {
						return '-';
					}
				},
			},
			{
				title: 'Biaya Admin BerbagiLink',
				dataIndex: 'admin_fee_berbagilink',
				key: 'admin_fee_berbagilink',
				render: (text, record) => {
					if (record.admin_fee_berbagilink > 0) {
						if (record.type_admin_fee_berbagilink === 'percentage') {
							return <div>{parseFloat(record.admin_fee_berbagilink)}% </div>
						} else {
							return <div>Rp. {numeral(Math.ceil(record.admin_fee_berbagilink)).format('0,0')} </div>
						}
					} else {
						return '-';
					}
				},
			},
			{
				title: 'Biaya Admin Channel',
				dataIndex: 'admin_fee_channel',
				key: 'admin_fee_channel',
				render: (text, record) => {
					if (record.admin_fee_channel > 0) {
						if (record.type_admin_fee_channel === 'percentage') {
							return <div>{parseFloat(record.admin_fee_channel)}% </div>
						} else {
							return <div>Rp. {numeral(Math.ceil(record.admin_fee_channel)).format('0,0')} </div>
						}
					} else {
						return '-';
					}
				},
			},
			{
				title: 'Status',
				dataIndex: 'is_active',
				key: 'is_active',
				render: (text, record) => <div><Switch onChange={val => onChange(val, record.id)} checked={record.is_active === 1 || record.is_active === true ? true : false} /></div>,
			},
			{
				title: 'Nama Biller',
				dataIndex: 'biller_name',
				key: 'biller_name',
				render: (text, record) => {
					if (record.biller_name === 'mutasi-bank') {
						return 'Mutasi Bank';
					} else if (record.biller_name === 'bisabiller') {
						return 'Bisabiller';
					} else if (record.biller_name === 'xendit') {
						return 'Xendit';
					} else if (record.biller_name) {
						return record.biller_name;
					} else {
						return '-';
					}
				},
			},
			{
				title: 'Action',
				render: (text, record) => (
					<div>
						<button
							onClick={() => onEditModal(record)}
							className="py-1.5 px-4 text-white text-sm font-bold rounded-xl bg-green-500"
						>
							Edit
						</button>
					</div>
				),
			},
		];
	};

	const fetchRequestPaymentMethod = async (pagination, sorter) => {
		setLoading(true);
		try {
			const response = await axiosConfig.get(`${API_ENDPOINT}/payment-methods/event-and-onlinestore`, {
				params: {
					page: pagination.current,
					limit: pagination.pageSize,
					payment_method: tipeTransaksi,
					name: paymentMethodName,
					type_of_payment: typeOfPayment,
				},
			});
			setData(response.data.data);
			setPaymentMethods(response.data.data.payment_methods.data);
			setPaginationState({
				current: response.data.data.payment_methods.current_page,
				pageSize: response.data.data.payment_methods.per_page,
				total: response.data.data.payment_methods.total,
			});
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data?.info ?? 'Terjadi suatu error saat pengambilan data',
			});
		} finally {
			setLoading(false);
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		if (isEmpty(sorter)) {
			fetchRequestPaymentMethod(pagination, {});
		} else {
			fetchRequestPaymentMethod(pagination, { field: sorter.field, sort: sorter.order });
		}
	};

	const fetchRequestPaymentMethodBySearch = async () => {
		let _paginationState = {
			current: 1,
			pageSize: paginationState.pageSize,
			total: paginationState.total
		};
		fetchRequestPaymentMethod(_paginationState)
	}

	useEffect(() => {
		fetchRequestPaymentMethodBySearch();
	}, [tipeTransaksi, paymentMethodName, typeOfPayment]);
	

	return (
		<Layout>
			<EditModal 
				modal={modal} 
				setModal={setModal} 
				fetchRequestPaymentMethod={fetchRequestPaymentMethod} 
				errors={errors} 
				onUpdateData={onUpdateData} 
			/>
			<div className="grid grid-cols-2 m-6 gap-6">
				<div className="col-span-2 bg-white grid rounded-md">
					<div className="py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom flex justify-between items-center">
						<p>
							Filter Metode Pembayaran Event &amp; Onlinestore
						</p>
					</div>
					<div className="py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom flex justify-end items-center">
						<FilterColumn
							setTipeTransaksi={setTipeTransaksi}
							setPaymentMethodName={setPaymentMethodName}
							setTypeOfPayment={setTypeOfPayment}
						/>
					</div>
				</div>
				<div className="col-span-2 bg-white">
					<div className="py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom">Tabel Metode Pembayaran Event &amp; Onlinestore</div>

					<Table
						columns={columns(setModal)}
						dataSource={paymentMethods}
						pagination={paginationState}
						loading={loading}
						onChange={handleTableChange}
						rowKey={record => record.id}
					/>
				</div>
			</div>
		</Layout>
	);
}

export default PaymentMethod;
