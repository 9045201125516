import React from 'react';
import numeral from '../../../utils/numeral';

function Saldo({ data }) {
	return (
		<div className="grid grid-cols-2 px-4 py-8 gap-4">
			<div className="justify-self-start">
				<h3 className="font-bold text-sm">Total Settlement</h3>
				<h3 className="text-xs mt-2">Total keseluruhan saldo settlements</h3>
			</div>
			<div className="font-bold text-2xl text-blue-600 justify-self-end">
				Rp. {numeral(data.balance_settlements?.settlement_balance).format('0,0')}
			</div>
			<div className="col-span-2 border-2 border-blue-600 rounded-full self-end"></div>
		</div>
	);
}

export default Saldo;
