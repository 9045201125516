import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	loading: 0,
	status: {},
};
const loading = createSlice({
	name: 'loading',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.loading += 1;
			return state;
		},
		setDone: (state, action) => {
			if (state.loading > 0) {
				state.loading -= 1;
			}
			return state;
		},
	},
});

export const { setLoading, setDone } = loading.actions;
export default loading.reducer;
