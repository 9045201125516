import { Select } from 'antd';
import { debounce, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';

const { Option } = Select;

function FilterColumn({ setAdminId }) {
	const [admins, setAdmins] = useState([]);
	const [loading, setLoading] = useState(false);

	const onSearchAdmins = debounce(async value => {
		try {
			setLoading(true);
			let response = await axiosConfig.get(`${API_ENDPOINT}/users/admin`, {
				params: { username: value },
			});
			setAdmins(response.data.data.admins);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}, 500);

	useEffect(() => {
		onSearchAdmins();
	}, []);

	return (
		<div className="flex gap-4 items-center">
			<Select
				className="w-52 font-normal"
				onChange={value => setAdminId(value)}
				showSearch
				onSearch={onSearchAdmins}
				loading={loading}
				notFoundContent={<div className="flex justify-center items-center">User Tidak ditemukan</div>}
				filterOption={false}
				placeholder="Cari Username Penarik"
				allowClear
			>
				{!isEmpty(admins) && <Option disabled>Pilih Username</Option>}
				<Option value={null}>Semua</Option>
				{admins.map(admin => (
					<Option key={admin.id} value={admin.id}>
						{admin.username}
					</Option>
				))}
			</Select>
		</div>
	);
}

export default FilterColumn;
