import React from "react";
import numeral from "../../../../utils/numeral";

function Saldo({ data, name, desc, color, count = false }) {
  return (
    <div className="grid grid-cols-2 px-4 py-8 gap-4">
      <div className="justify-self-start">
        <h3 className="font-bold text-sm">{name}</h3>
        <h3 className="text-xs mt-2">{desc}</h3>
      </div>
      <>
        <div
          className={`font-bold text-2xl text-${color}-600 justify-self-end`}
        >
          {count
            ? numeral(data).format("0,0")
            : "Rp" + numeral(data).format("0,0")}
        </div>
        <div
          className={`col-span-2 border-2 border-${color}-600 rounded-full self-end`}
        ></div>
      </>
    </div>
  );
}

export default Saldo;
