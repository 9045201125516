import { DatePicker, Select } from 'antd';
import moment from 'moment';
import React from 'react';

const { Option } = Select;
const { RangePicker } = DatePicker;

function FilterDate({ date, setDate }) {
	const onChangeDate = (date, dateStrings) => {
		setDate({
			date_from: date[0],
			date_to: date[1],
		});
	};
	const disabledDate = current => {
		return current && current > moment();
	};

	return (
		<RangePicker
			className="min-w-fit"
			onChange={onChangeDate}
			disabledDate={disabledDate}
			defaultValue={[moment(), moment()]}
			ranges={{
				'Hari Ini': [moment(), moment()],
				Kemarin: [moment().subtract(1, 'days'), moment()],
				'Minggu ini': [moment().subtract(1, 'week'), moment()],
				'Minggu lalu': [moment().subtract(2, 'weeks'), moment()],
				'Bulan ini': [moment().subtract(1, 'month'), moment()],
				'Bulan lalu': [moment().subtract(2, 'months'), moment().subtract(1, 'month')],
				'Tahun Ini': [moment().subtract(1, 'year'), moment()],
			}}
			format="DD-MM-YYYY"
		/>
	);
}

export default FilterDate;
