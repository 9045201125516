import { DatePicker, Select, Table } from "antd";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FilterDate from "../../components/FilterDate";
import Layout from "../../layout/Layout";
import { Toast } from "../../reusable/Toast";
import axiosConfig from "../../utils/axiosConfig";
import { API_ENDPOINT } from "../../utils/config";
import numeral from "../../utils/numeral";
import FilterColumn from "./_partials/FilterColumn";
import Saldo from "./_partials/Saldo";

const initialPagination = {
  current: 1,
  pageSize: 10,
};

const { Option } = Select;
const { RangePicker } = DatePicker;

function Transaction() {
  const columns = [
    {
      title: "No",
      render: (record, item, index) => {
        return (
          <>
            {(paginationState.current - 1) * paginationState.pageSize +
              index +
              1}
          </>
        );
      },
      key: "no",
    },
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "2rem",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text, record, index) => record.user_wallet.user.username,
    },
    {
      title: "Tipe Transaksi",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text, record) => {
        if (text === "App\\Models\\BillLink") {
          return <>Penagihan</>;
        } else if (text === "App\\Models\\EventTransaction") {
          return <>Event</>;
        } else if (text === "App\\Models\\Transaction") {
          return <>OnlineStore</>;
        } else if (text === "App\\Models\\SupportTransaction") {
          return <>Support</>;
        } else {
          return <>QRIS</>;
        }
      },
      sorter: true,
    },
    {
      title: "Nama Item",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text, record) => {
        if (text === "App\\Models\\EventTransaction") {
          return <>{record.transaction.item.nama_event}</>;
        } else if (text === "App\\Models\\BillLink") {
          return <>{record.transaction.item.name}</>;
        } else if (text === "App\\Models\\Transaction") {
          return <>{record.transaction.item[0].nama}</>;
        } else if (text === "App\\Models\\SupportTransaction") {
          return <>{record.transaction.item.username}</>;
        } else {
          return <>{record.transaction.name}</>;
        }
      },
    },
    {
      title: "Saldo Sebelum",
      dataIndex: "saldo_sebelum",
      key: "saldo_sebelum",
      render: (text, record) => (
        <>Rp{numeral(record.saldo_sebelum).format("0,0")}</>
      ),
    },
    {
      title: "Saldo Sesudah",
      dataIndex: "saldo_sesudah",
      key: "saldo_sesudah",
      render: (text, record) => (
        <>Rp{numeral(record.saldo_sesudah).format("0,0")}</>
      ),
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      key: "nominal",
      render: (text, record) => {
        if (text > 0) {
          return (
            <p className="text-green-600">
              Rp{numeral(record.nominal).format("0,0")}
            </p>
          );
        } else {
          return (
            <p className="text-red-600">
              Rp{numeral(record.nominal).format("0,0")}
            </p>
          );
        }
      },
      sorter: true,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return (
          <p className="text-blue-600">
            Rp{" "}
            {numeral(
              record.transaction.total_plus_admin
                ? record.transaction.total_plus_admin
                : record.transaction.total
            ).format("0,0")}
          </p>
        );
      },
    },
    {
      title: "Metode Pembayaran",
      dataIndex: "transaction.nama_bank",
      key: "transaction.nama_bank",
      render: (text, record) => <>{record.transaction.nama_bank}</>,
    },
    {
      title: "Admin Channel",
      dataIndex: "transaction.admin_channel",
      key: "transaction.admin_channel",
      render: (text, record) => (
        <>Rp{numeral(record.transaction.admin_channel).format("0,0")}</>
      ),
    },
    {
      title: "Admin Berbagilink",
      dataIndex: "transaction.admin_berbagi_link",
      key: "transaction.admin_berbagi_link",
      render: (text, record) => {
        if (record.transaction_type === "App\\Models\\SupportTransaction") {
          return (
            <>Rp{numeral(record.transaction.admin_platform).format("0,0")}</>
          );
        } else {
          return (
            <>
              Rp{numeral(record.transaction.admin_berbagi_link).format("0,0")}
            </>
          );
        }
      },
    },
    {
      title: "Dibuat Pada",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => <>{record.created_at}</>,
      sorter: true,
    },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [paginationState, setPaginationState] = useState(initialPagination);
  const [userId, setUserId] = useState(null);
  const [tipeTransaksi, setTipeTransaksi] = useState(null);
  const [paymentMethodId, setPaymentMethodId] = useState(null);
  const [itemId, setItemId] = useState(null);

  const [date, setDate] = useState({
    date_from: moment(),
    date_to: moment(),
  });

  const option = [
    { key: "App\\Models\\Transaction", value: "Onlinestore" },
    { key: "App\\Models\\EventTransaction", value: "Event" },
    { key: "App\\Models\\OnlineCourseTransaction", value: "Onlinecourse" },
    { key: "App\\Models\\BerbagilinkTransaction", value: "Berlangganan" },
  ];

  const setExport = async () => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `${API_ENDPOINT}/transaction/export`,
        {
          params: {
            date_from: date.date_from.format("YYYY-MM-DD"),
            date_to: date.date_to.format("YYYY-MM-DD"),
            item: itemId,
            payment_method: paymentMethodId,
            transaction_type: tipeTransaksi,
            user: userId,
          },
        }
      );
      const link = document.createElement("a");
      link.href = response.data.data;
      link.download = "Riwayat Penarikan Berbagilink.xlsx";
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title:
          error.response.data?.info ??
          "Terjadi suatu error saat pengambilan data",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchRequestTransaction = async (pagination, sorter) => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(`${API_ENDPOINT}/transaction`, {
        params: {
          page: pagination.current,
          limit: pagination.pageSize,
          user_id: userId,
          type: tipeTransaksi,
          payment_id: paymentMethodId,
          date_from: date.date_from.format("YYYY-MM-DD"),
          date_to: date.date_to.format("YYYY-MM-DD"),
          item_id: itemId,
          ...sorter,
        },
      });
      setData(response.data.data);
      setTransactions(response.data.data.transaction_logs.data);
      setPaginationState({
        current: response.data.data.transaction_logs.current_page,
        pageSize: response.data.data.transaction_logs.per_page,
        total: response.data.data.transaction_logs.total,
      });
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title:
          error.response.data?.info ??
          "Terjadi suatu error saat pengambilan data",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequestTransaction(paginationState);
  }, [userId, date, tipeTransaksi, paymentMethodId, itemId]);

  const handleTableChange = (pagination, filters, sorter) => {
    if (isEmpty(sorter)) {
      fetchRequestTransaction(pagination, {});
    } else {
      fetchRequestTransaction(pagination, {
        field: sorter.field,
        sort: sorter.order,
      });
    }
  };

  return (
    <Layout>
      <div className="grid grid-cols-2 m-6 gap-6">
        <div className="col-span-2 bg-white grid rounded-md overflow-x-scroll">
          <div className="py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom flex justify-between items-center">
            <p>
              Laporan Transaksi Otomatis pada{" "}
              {moment().diff(date.date_from, "days") > 0
                ? `${date.date_from.format(
                    "DD-MM-YYYY"
                  )} - ${date.date_to.format("DD-MM-YYYY")}`
                : "Hari Ini"}
            </p>
            <FilterDate
              date={date}
              setDate={setDate}
              userId={userId}
              setUserId={setUserId}
            />
          </div>
          <div className="py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom items-center">
            <FilterColumn
              setUserId={setUserId}
              setTipeTransaksi={setTipeTransaksi}
              setPaymentMethodId={setPaymentMethodId}
              setItemId={setItemId}
              setExport={setExport}
              options={option}
              type="otomatis"
            />
          </div>
        </div>
        <div className="col-span-2 grid grid-cols-3 gap-6">
        <div className="bg-white">
          <Saldo
            data={data.transaction_count}
            name={"Total Transaksi"}
            desc={"Total transaksi keseluruhan dari pengguna"}
            color="purple"
            count={true}
          />
        </div>
        <div className="bg-white">
          <Saldo
            data={data.transaction}
            name={"Nominal Transaksi"}
            desc={"Nominal transaksi keseluruhan"}
            color="orange"
          />
        </div>
        <div className="bg-white">
          <Saldo
            data={data.settlement}
            name={"Total Settlement"}
            desc={"Total settlement dari pengguna"}
            color="red"
          />
        </div>
        </div>
        <div className="bg-white">
          <Saldo
            data={data.balance}
            name={"Total Saldo"}
            desc={"Total saldo keseluruhan Berbagi.link"}
            color="blue"
          />
        </div>
        <div className="bg-white">
          <Saldo
            data={data.profit}
            name={"Total Profit"}
            desc={"Total profit keseluruhan dari pengguna"}
            color="green"
          />
        </div>
        <div className="bg-white">
          <Saldo
            data={data.admin_channel}
            name={"Total Admin Channel"}
            desc={"Total biaya yang harus dibayarkan ke penyedia jasa"}
            color={"blue"}
          />
        </div>
        <div className="bg-white">
          <div className="col-span-2 px-4 py-8 gap-4">
            <div className="grid grid-cols-3">
              <div className="mx-2">
                <h3 className="font-bold text-sm text-start">Profit VA</h3>
                <div className={`font-bold text-2xl text-green-600 text-end`}>
                  Rp{numeral(data.va).format("0,0")}
                </div>
              </div>
              <div className="mx-2">
                <h3 className="font-bold text-sm text-start">Profit QRIS</h3>
                <div className={`font-bold text-2xl text-green-600 text-end`}>
                  Rp{numeral(data.qris).format("0,0")}
                </div>
              </div>
              <div className="mx-2">
                <h3 className="font-bold text-sm text-start">
                  Profit E-Wallet
                </h3>
                <div className={`font-bold text-2xl text-green-600 text-end`}>
                  Rp{numeral(data.ewallet).format("0,0")}
                </div>
              </div>
            </div>

            <div
              className={`col-span-2 border-2 border-green-600 rounded-full self-end mt-2`}
            ></div>
          </div>
        </div>
        <div className="col-span-2 bg-white">
          <div className="py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom">
            Tabel Transaksi
          </div>

          <Table
            className="overflow-x-scroll"
            columns={columns}
            dataSource={transactions}
            pagination={paginationState}
            loading={loading}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Transaction;
