import { DatePicker, Input, Select } from 'antd';
import moment from 'moment/moment';
import React, { useState } from 'react';
import TotalSettlement from '../../components/TotalSettlement';
import Layout from '../../layout/Layout';
import TotalSubAccountXenditSaldo from './_partials/TotalSubAccountXenditSaldo';
import TotalXenditSaldo from './_partials/TotalXenditSaldo';
import TotalBisabillerSaldo from './_partials/TotalBisabillerSaldo';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

const columnSettlements = [
	{
		title: 'ID',
		render: (record, item, index) => index + 1,
		sorter: true,
	},
	{
		title: 'Name',
		dataIndex: 'name',
		sorter: true,
	},
	{
		title: 'Phone Number',
		dataIndex: 'phone_number',
		sorter: true,
	},
	{
		title: 'Email',
		dataIndex: 'email',
		sorter: true,
	},
	{
		title: 'Device ID',
		dataIndex: 'device_id',
		sorter: true,
	},
	{
		title: 'IP Address',
		dataIndex: 'ip_address',
		sorter: true,
	},
	{
		title: 'Verified',
		dataIndex: 'verified',
		sorter: true,
		render: (record, item, index) => {
			if (record === 'verified') {
				return (
					<div className="p-2 bg-primary text-white font-bold flex items-center justify-center rounded-lg">
						Verified
					</div>
				);
			}
		},
	},
	{
		title: 'Saldo',
		dataIndex: 'saldo',
		sorter: true,
	},
	{
		title: 'Created At',
		dataIndex: 'created_at',
		sorter: true,
	},
	{
		title: 'Updated At',
		dataIndex: 'updated_at',
		sorter: true,
	},
];

function Dashboard() {
	const [date, setDate] = useState({
		date_from: moment().subtract(1, 'week'),
		date_to: moment(),
	});
	const [source, setSource] = useState('all');
	const onChangeDate = (date, dateStrings) => {
		setDate({
			date_from: date[0],
			date_to: date[1],
		});
	};
	const onChangeSourceFunds = (source) => {
		setSource(source);
	}
	const disabledDate = current => {
		return current && current > moment();
	};
	return (
		<Layout>
			<header className="py-3 px-6 border-t border-gray-300 bg-white w-full flex justify-between items-center">
				<div className="flex gap-3 items-center">
					<div>Dashboard</div>
					<div className="border-r h-4 border-gray-400"></div>
					<div className="text-gray-400">Berbagi.link</div>
				</div>
				<div className="flex gap-4 items-center">
					<Select onChange={onChangeSourceFunds} defaultValue={'all'} className="w-72">
						<Option value="all">Semua</Option>
						<Option value="Umum">Bisabiller (Umum)</Option>
						<Option value="HalalFair">Bisabiller (HalalFair)</Option>
						<Option value="Hiro">Bisabiller (Hiro)</Option>
						<Option value="Tahseel">Bisabiller (Tahseel)</Option>
						<Option value="IIETE">Bisabiller (IIETE)</Option>
						<Option value="Babussalam">Bisabiller (Babussalam)</Option>
					</Select>
					<RangePicker
						onChange={onChangeDate}
						disabledDate={disabledDate}
						defaultValue={[moment().subtract(1, 'week'), moment()]}
						ranges={{
							'Hari Ini': [moment(), moment()],
							Kemarin: [moment().subtract(1, 'days'), moment()],
							'Minggu ini': [moment().subtract(1, 'week'), moment()],
							'Minggu lalu': [moment().subtract(2, 'weeks'), moment()],
							'Bulan ini': [moment().subtract(1, 'month'), moment()],
							'Bulan lalu': [moment().subtract(2, 'months'), moment().subtract(1, 'month')],
							'Tahun Ini': [moment().subtract(1, 'year'), moment()],
						}}
						format="DD-MM-YYYY"
					/>
				</div>
			</header>
			<div className="grid grid-cols-2 m-6 gap-6">
				<TotalBisabillerSaldo date={date} source={source}/>
				{/* <TotalXenditSaldo />
				<TotalSubAccountXenditSaldo /> */}
				<TotalSettlement date={date} />
				{/* <DataTransaction /> */}
				{/* <PaymentReport /> */}
				{/* <SettlementReport /> */}
			</div>
		</Layout>
	);
}

export default Dashboard;
