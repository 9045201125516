import { RouterProvider } from 'react-router-dom';
import './App.css';
import PreRequest from './inherit/PreRequest';
import { router } from './utils/route';

function App() {
	return (
		<div className="App">
			<PreRequest>
				<RouterProvider router={router} />
			</PreRequest>
		</div>
	);
}

export default App;
