import { DatePicker, Select, Table } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import FilterDate from '../../components/FilterDate';
import Layout from '../../layout/Layout';
import axiosConfig from '../../utils/axiosConfig';
import { API_ENDPOINT } from '../../utils/config';
import numeral from '../../utils/numeral';
import CreateModal from './_partials/CreateModal';
import FilterColumn from './_partials/FilterColumn';
import Saldo from './_partials/Saldo';

const initialPagination = {
	current: 1,
	pageSize: 10,
};

const { Option } = Select;
const { RangePicker } = DatePicker;

function Settlement() {
	const columns = [
		{
			title: 'No',
			render: (record, item, index) => {
				return <>{(paginationState.current - 1) * paginationState.pageSize + index + 1}</>;
			},
			key: 'no',
		},
		{
			title: 'Tanggal Settlement',
			dataIndex: 'settlement_date',
			key: 'settlement_date',
			render: (text, record) => <>{record.settlement_date}</>,
		},
		{
			title: 'Ditarik oleh',
			dataIndex: 'admin.name',
			key: 'admin.name',
			render: (text, record, index) => record.admin.name,
		},
		{
			title: 'Nominal',
			dataIndex: 'nominal',
			key: 'nominal',
			render: (text, record) => <>Rp. {numeral(record.nominal).format('0,0')}</>,
			sorter: true,
		},
		{
			title: 'Dibuat Pada',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text, record) => <>{record.created_at}</>,
			sorter: true,
		},
	];

	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [paginationState, setPaginationState] = useState(initialPagination);
	const [totalData, setTotalData] = useState(0);
	const [sort, setSort] = useState({
		field: null,
		sort: null,
	});
	const [adminId, setAdminId] = useState(null);
	const [openModal, setOpenModal] = useState(false);

	const [date, setDate] = useState({
		date_from: moment(),
		date_to: moment(),
	});

	const fetchSettlements = async () => {
		setLoading(true);
		try {
			const response = await axiosConfig.get(`${API_ENDPOINT}/settlements`, {
				params: {
					page: paginationState.current,
					limit: paginationState.pageSize,
					admin_id: adminId,
					date_from: date.date_from.format('YYYY-MM-DD'),
					date_to: date.date_to.format('YYYY-MM-DD'),
					...sort,
				},
			});
			setData(response.data.data);
			setTotalData(response.data.data.settlements.total);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchSettlements();
	}, [adminId, date, paginationState, sort]);

	const handleTableChange = (pagination, filters, sorter) => {
		if (!isEmpty(sorter)) {
			setSort({ field: sorter.field, sort: sorter.order });
		}
		setPaginationState({
			current: pagination.current,
			pageSize: pagination.pageSize,
		});
	};

	const showModal = () => {
		setOpenModal(true);
	};

	return (
		<Layout>
			<CreateModal openModal={openModal} setOpenModal={setOpenModal} fetchSettlements={fetchSettlements} />
			<div className="grid grid-cols-2 m-6 gap-6">
				<div className="col-span-2 bg-white grid rounded-md overflow-x-scroll">
					<div className="py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom flex justify-between items-center">
						<p>
							Total Settlement pada{' '}
							{moment().diff(date.date_from, 'days') > 0
								? `${date.date_from.format('DD-MM-YYYY')} - ${date.date_to.format('DD-MM-YYYY')}`
								: 'Hari Ini'}
						</p>
						<FilterDate date={date} setDate={setDate} />
					</div>
					<div className="py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom flex justify-end items-center">
						<FilterColumn setAdminId={setAdminId} />
					</div>
				</div>
				<div className="col-span-2 bg-white">
					<Saldo data={data} />
				</div>
				<div className="col-span-2 bg-white">
					<div className="py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom flex justify-between items-center">
						<p>Tabel Settlement</p>
						<button
							onClick={showModal}
							className="flex gap-3 items-center border-2 border-green-500 text-green-500 rounded-full bg-white px-4 py-1"
						>
							<span className="text-xl font-bold">+</span>
							<span>Add Settlement</span>
						</button>
					</div>
					<Table
						columns={columns}
						dataSource={data.settlements?.data}
						pagination={{ ...paginationState, total: totalData }}
						loading={loading}
						onChange={handleTableChange}
						rowKey={record => record.id}
					/>
				</div>
			</div>
		</Layout>
	);
}

export default Settlement;
