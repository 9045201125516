import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { Toast } from '../../../reusable/Toast';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';
import numeral from '../../../utils/numeral';

const loadingIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

function TotalXenditSaldo() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});

	const fetchBalanceXendit = async (pagination, sorter) => {
		setLoading(true);
		try {
			const response = await axiosConfig.get(`${API_ENDPOINT}/xendit/balance`, {});
			setData(response.data.data);
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data?.info ?? 'Terjadi Suatu Error',
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchBalanceXendit();
	}, []);

	const totalBalance = () => {
		if (data.available_balance !== null && data.pending_balance !== null) {
			return data.available_balance + data.pending_balance;
		}
		return '0';
	};

	return (
		<div className="col-span-2 bg-white grid grid-cols-3 rounded-md relative">
			{loading && (
				<div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
					<Spin indicator={loadingIcon} />
				</div>
			)}
			<div className="col-span-3 py-3 px-4 border-b-2 border-gray-300 font-bold text-gray-custom">
				Total Saldo Xendit Utama (Profit) pada Hari ini
			</div>
			<div className="grid grid-cols-2 px-4 py-8 gap-4">
				<div className="justify-self-start">
					<h3 className="font-bold text-sm">Saldo Tersedia</h3>
					<h3 className="text-xs mt-2">Saldo yang dapat diambil dari xendit</h3>
				</div>
				<div className="font-bold text-2xl text-blue-600 justify-self-end">
					Rp. {data.available_balance ? numeral(data.available_balance).format('0,0') : '0'}
				</div>
				<div className="col-span-2 border-2 border-blue-600 rounded-full self-end"></div>
			</div>
			<div className="grid grid-cols-2 px-4 py-8 gap-4 border-r-2 border-l-2 border-gray-300">
				<div className="justify-self-start">
					<h3 className="font-bold text-sm">Saldo Pending</h3>
					<h3 className="text-xs mt-2">Saldo yang sedang pending oleh xendit</h3>
				</div>
				<div className="font-bold text-2xl text-green-600 justify-self-end">
					Rp. {data.pending_balance ? numeral(data.pending_balance).format('0,0') : '0'}
				</div>
				<div className="col-span-2 border-2 border-green-600 rounded-full self-end"></div>
			</div>
			<div className="grid grid-cols-2 px-4 py-8 gap-4">
				<div className="justify-self-start">
					<h3 className="font-bold text-sm">Total Saldo</h3>
					<h3 className="text-xs mt-2">Total keseluruhan saldo xendit</h3>
				</div>
				<div className="font-bold text-2xl text-red-600 justify-self-end">
					Rp. {numeral(totalBalance()).format('0,0')}
				</div>
				<div className="col-span-2 border-2 border-red-600 rounded-full self-end"></div>
			</div>
		</div>
	);
}

export default TotalXenditSaldo;
