import React, { useEffect, useState } from "react";
import Layout from "../../../layout/Layout";
import moment from "moment";
import { DatePicker, Input, Select, Spin, Table } from "antd";
import numeral from "numeral";
import {
  AreaChartOutlined,
  ControlOutlined,
  EyeFilled,
  LoadingOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import { setLoading } from "../../../store/loading";
import axiosConfig from "../../../utils/axiosConfig";
import { API_ENDPOINT } from "../../../utils/config";
import { isEmpty } from "lodash";
import { Toast } from "../../../reusable/Toast";
import DetailModal from "./_partials/DetailModal";
import Saldo from "./_partials/Saldo";

const { Search } = Input;
const { RangePicker } = DatePicker;

const initialPagination = {
  current: 1,
  pageSize: 10,
};
const loadingIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
const columns = (setModal) => {
  const openViewModal = (log) => {
    setModal({
      type: "view",
      log,
    });
  };
  return [
    {
      title: "ID",
      render: (record, item) => item.id,
      sorter: true,
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Nama",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (record, item) => {
        return (
          <div className="flex w-48 gap-3">
            <img
              className="rounded-full w-10 h-10 my-auto"
              src={
                item.profile_image
                  ? item.profile_image
                  : "https://www.iconpacks.net/icons/2/free-user-icon-3296-thumb.png"
              }
              alt=""
            />
            <p className="my-auto">{item.name}</p>
          </div>
        );
      },
    },
    {
      title: "Handphone",
      dataIndex: "phone_number",
      sorter: true,
      key: "phone_number",
      render: (record, item) => item.phone_number,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
      key: "email",
      render: (record, item) => item.email,
    },
    {
      title: "Saldo On Process",
      dataIndex: "saldo",
      sorter: false,
      key: "saldo_progress",
      render: (record, item) => (
        <>Rp. {numeral(item.saldo_progress).format("0,0")}</>
      ),
    },
    {
      title: "Saldo Aktif",
      dataIndex: "saldo",
      sorter: false,
      key: "saldo_aktif",
      render: (record, item) => (
        <>Rp. {numeral(item.saldo - item.saldo_progress).format("0,0")}</>
      ),
    },
    {
      title: "Total Saldo",
      dataIndex: "saldo",
      sorter: true,
      key: "saldo",
      render: (record, item) => <>Rp. {numeral(item.saldo).format("0,0")}</>,
    },
    {
      title: "Saldo Tertarik",
      dataIndex: "pulled_saldo",
      sorter: true,
      key: "pulled_saldo",
      render: (record, item) => (
        <>Rp. {numeral(item.pulled_saldo).format("0,0")}</>
      ),
    },
    {
      title: "Dibuat Pada",
      dataIndex: "dibuat",
      sorter: true,
      key: "dibuat",
      render: (record, item) => item.dibuat,
    },
    {
      title: "Diubah Pada",
      dataIndex: "diupdate",
      sorter: true,
      key: "diupdate",
      render: (record, item) => item.diupdate,
    },
    {
      title: "#Action",
      dataIndex: "action",
      key: "action",
      render: (record, item) => {
        return (
          <div className="flex">
            <a className="mx-auto" onClick={() => openViewModal(item)}>
              <EyeFilled style={{ fontSize: "18px" }} />
            </a>
          </div>
        );
      },
    },
  ];
};

function Data() {
  const [date, setDate] = useState({
    date_from: moment().subtract(1, "week"),
    date_to: moment(),
  });
  const [search, setSearch] = useState("");
  const [paginationState, setPaginationState] = useState(initialPagination);
  const [customer, setCustomer] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({});
  const [data, setData] = useState({});
  const onChangeDate = (date, dateStrings) => {
    setDate({
      date_from: date[0],
      date_to: date[1],
    });
  };
  const disabledDate = (current) => {
    return current && current > moment();
  };

  const handleTableChange = (pagination, filter, sorter) => {
    console.log(sorter);
    if (isEmpty(sorter)) {
      fetchCustomerDatatable(pagination, {});
    } else {
      fetchCustomerDatatable(pagination, {
        field: sorter.field,
        sort: sorter.order,
      });
    }
  };

  const onSearch = (value) => {
    setSearch(value);
  };

  const fetchCustomerDatatable = async (pagination, sorter) => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `${API_ENDPOINT}/customer/datatable`,
        {
          params: {
            page: pagination.current,
            limit: pagination.pageSize,
            date_from: date.date_from.format("YYYY-MM-DD"),
            date_to: date.date_to.format("YYYY-MM-DD"),
            search,
            ...sorter,
          },
        }
      );

      const responseHeader = await axiosConfig.get(
        `${API_ENDPOINT}/customer/data`
      );
      setCustomer(response.data.data.data);
      setData(responseHeader.data.data);
      setPaginationState({
        current: response.data.data.current_page,
        pageSize: response.data.data.per_page,
        total: response.data.data.total,
      });
      console.log(customer, paginationState);
    } catch (error) {
      console.log(error);
      Toast.fire({
        icon: "error",
        title:
          error.response.data?.info ??
          "Terjadi suatu error saat pengambilan data",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomerDatatable(paginationState);
  }, [search, date]);

  return (
    <Layout>
      <DetailModal
        modal={modal}
        setModal={setModal}
        fetchRequestWithdraw={fetchCustomerDatatable}
      />
      <header className="py-3 px-6 border-t border-gray-300 bg-white w-full flex justify-between items-center">
        <div className="flex gap-3 items-center">
          <div>Data Customer</div>
          <div className="border-r h-4 border-gray-400"></div>
          <div className="text-gray-400">Berbagi.link</div>
        </div>
        <div className="flex gap-4 items-center">
          {/* <RangePicker
            onChange={onChangeDate}
            disabledDate={disabledDate}
            defaultValue={[moment().subtract(1, "week"), moment()]}
            ranges={{
              "Hari Ini": [moment(), moment()],
              Kemarin: [moment().subtract(1, "days"), moment()],
              "Minggu ini": [moment().subtract(1, "week"), moment()],
              "Minggu lalu": [moment().subtract(2, "weeks"), moment()],
              "Bulan ini": [moment().subtract(1, "month"), moment()],
              "Bulan lalu": [
                moment().subtract(2, "months"),
                moment().subtract(1, "month"),
              ],
              "Tahun Ini": [moment().subtract(1, "year"), moment()],
            }}
            format="DD-MM-YYYY"
          /> */}
        </div>
      </header>
      <div className="grid rounded-md m-6">
        <div className="grid grid-cols-3 gap-3">
          <div className="bg-white relative">
            {loading && (
              <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
                <Spin indicator={loadingIcon} />
              </div>
            )}
            <Saldo
              data={data.user}
              name={"Total Customer Instant Payment"}
              desc={
                "Jumlah pengguna yang diberikan akses untuk menggunakan fitur instant payment"
              }
              count={true}
              color="blue"
            />
          </div>
          <div className="bg-white relative">
            {loading && (
              <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
                <Spin indicator={loadingIcon} />
              </div>
            )}
            <Saldo
              data={data.saldo}
              name={"Total Saldo"}
              desc={
                "Jumlah saldo yang dimiliki seluruh pengguna Berbagi.link saat ini"
              }
              color="green"
            />
          </div>
          <div className="bg-white relative">
            {loading && (
              <div className="absolute top-0 left-0 w-full h-full bg-gray-custom bg-opacity-10 z-10 flex justify-center items-center">
                <Spin indicator={loadingIcon} />
              </div>
            )}
            <Saldo
              data={data.withdraw}
              name={"Total Saldo Ajuan Penarikan"}
              desc={
                "Jumlah saldo yang diajukan untuk ditarik oleh seluruh pengguna berbagi.link"
              }
              color="red"
            />
          </div>
        </div>
      </div>
      <div className="bg-white grid rounded-md m-6">
        <div className="py-2 px-4 border-b-2 flex gap-2">
          <UserSwitchOutlined
            className="my-auto text-blue-600"
            style={{ fontSize: "16px" }}
          />
          <div className=" border-gray-300 font-bold text-gray-custom text-lg">
            Data Customer
          </div>
        </div>
        <div className="py-3 px-4 overflow-x-scroll">
          <div className="flex items-end justify-end mt-3 mb-3">
            <Search
              placeholder="Masukkan pencarian"
              className="max-w-xxs"
              allowClear
              enterButton="Cari"
              onSearch={onSearch}
            />
          </div>
          <Table
            columns={columns(setModal)}
            dataSource={customer}
            pagination={paginationState}
            loading={loading}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Data;
