import { Form, Input, Modal } from 'antd';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import FormItem from '../../../../reusable/FormItem';
import { Toast } from '../../../../reusable/Toast';
import { setDone, setLoading } from '../../../../store/loading';
import axiosConfig from '../../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../../utils/config';
import { getErrorValue } from '../../../../utils/getErrors';
import numeral from '../../../../utils/numeral';

const initialValues = {
	catatan: 'Penarikan saldo gagal, silahkan coba kembali',
};

const validationSchema = Yup.object().shape({
	catatan: Yup.string().required('Catatan wajib diisi'),
});

function RejectModal({ modal, setModal, fetchRequestWithdraw }) {
	const [errorMessage, setErrorMessage] = useState({});
	const dispatch = useDispatch();

	const onSubmit = async values => {
		dispatch(setLoading());
		try {
			const response = await axiosConfig.post(`${API_ENDPOINT}/withdraw/reject/${modal.log.id}`, {
				catatan: values.catatan,
			});
			Toast.fire({
				icon: 'success',
				title: 'Berhasil Menolak Penarik',
			});
			fetchRequestWithdraw({
				current: 1,
				pageSize: 10,
			});
			setErrorMessage({});
			onClose();
		} catch (error) {
			console.log(error);
			Toast.fire({
				icon: 'error',
				title: error.response.data.info,
			});
			setErrorMessage(error.response.data.data.errors);
		} finally {
			dispatch(setDone());
		}
	};

	const formik = useFormik({
		initialValues,
		onSubmit,
		validationSchema,
	});

	const onClose = () => {
		formik.resetForm();
		setModal({});
	};

	return (
    <Modal
      onCancel={onClose}
      open={modal.type === "reject"}
      title="Tolak Ajuan Penarikan"
      onOk={formik.handleSubmit}
      okText="Tolak"
      okButtonProps={{
        className:
          "bg-red-500 border-red-500 hover:bg-red-400 hover:border-red-400",
      }}
      destroyOnClose
    >
      {!isEmpty(modal) && (
        <div className="grid grid-cols-2 gap-y-2 items-center">
          <p className="font-bold text-lg">Username:</p>
          <p className="text-lg justify-self-end">{modal.log.user.username}</p>
          <p className="font-bold text-lg">Bank:</p>
          <p className="text-lg justify-self-end">{modal.log.bank}</p>
          <p className="font-bold text-lg">Nama Pemilik Rekening:</p>
          <p className="text-lg justify-self-end">
            {modal.log.nama_pemilik_rekening}
          </p>
          <p className="font-bold text-lg">Nomor Rekening:</p>
          <p className="text-lg justify-self-end">{modal.log.nomor_rekening}</p>
          <p className="font-bold text-lg">Nominal:</p>
          <p className="text-lg justify-self-end">
            Rp. {numeral(modal.log.nominal).format("0,0")}
          </p>
          <p className="font-bold text-lg">Biaya Admin:</p>
          <p className="text-lg justify-self-end">
            Rp. {numeral(modal.log.admin_fee).format("0,0")}
          </p>
          <p className="font-bold text-lg">Total:</p>
          <p className="text-lg justify-self-end">
            Rp. {numeral(modal.log.total).format("0,0")}
          </p>
          <p className="font-bold text-lg">Tanggal Pengajuan:</p>
          <p className="text-lg justify-self-end">{modal.log.created_at}</p>
          <p className="font-bold text-lg">Status:</p>
          <p className="text-lg justify-self-end bg-blue-500 text-white px-4 font-bold rounded-lg">
            {modal.log.status}
          </p>
        </div>
      )}
      <div className="w-full border border-gray-200 my-4"></div>
      <Form layout="vertical">
        <h1 className="text-lg font-bold text-gray-600 my-2">Isi Data</h1>
        <FormItem
          label="Catatan Penolakan"
          error={getErrorValue(formik.errors.catatan, errorMessage?.catatan)}
          touched={formik.touched.catatan}
        >
          <Input.TextArea
            name="catatan"
            value={formik.values.catatan}
            placeholder="Masukan alasan kenapa ditolak"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </FormItem>
      </Form>
    </Modal>
  );
}

export default RejectModal;
