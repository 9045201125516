import { Select, Input } from 'antd';
import { debounce, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import axiosConfig from '../../../utils/axiosConfig';
import { API_ENDPOINT } from '../../../utils/config';

const { Option } = Select;

function FilterColumn({ setTipeTransaksi, setPaymentMethodName, setTypeOfPayment }) {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [loading, setLoading] = useState(false);

	const onSearchPayments = debounce(async value => {
		try {
			setLoading(true);
			setPaymentMethodName(value);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}, 500);

	useEffect(() => {
		onSearchPayments();
	}, []);

	return (
		<div className="flex gap-4 items-center">
			<Input 
				placeholder="Masukkan pencarian"
				className="w-52 font-normal"
				onChange={e => onSearchPayments(e.target.value)}
			/>
			<Select
				className="w-52 font-normal"
				onChange={value => setTipeTransaksi(value)}
				notFoundContent={<div className="flex justify-center items-center">Tipe Transaksi tidak ditemukan</div>}
				placeholder="Tipe Transaksi"
			>
				<Option value={''}>Semua</Option>
				<Option value={'bank'}>Bank Transfer</Option>
				<Option value={'e-wallet'}>E-Wallet</Option>
				<Option value={'qris'}>QRIS</Option>
				<Option value={'retail'}>Gerai</Option>
			</Select>
			<Select
				className="w-52 font-normal"
				onChange={value => setTypeOfPayment(value)}
				notFoundContent={<div className="flex justify-center items-center">Tipe pembayaran tidak ditemukan</div>}
				placeholder="Tipe Pembayaran"
			>
				<Option value={''}>Semua</Option>
				<Option value={'manual'}>Manual</Option>
				<Option value={'otomatis'}>Otomatis</Option>
			</Select>
		</div>
	);
}

export default FilterColumn;
