import { EyeFilled } from "@ant-design/icons";
import { Input, Table } from "antd";
import { isEmpty } from "lodash";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { API_ENDPOINT } from "../../../../utils/config";
import axiosConfig from "../../../../utils/axiosConfig";
import { Toast } from "../../../../reusable/Toast";

const { Search } = Input;

const initialPagination = {
  current: 1,
  pageSize: 10,
};

function TableCustomer(props) {
  const [paginationState, setPaginationState] = useState(initialPagination);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "2rem",
      render: (text, record, index) => record.transaction.invoice,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text, record, index) => record.user_wallet.user.username,
    },
    {
      title: "Nama Item",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text, record) => {
        if (text === "App\\Models\\EventTransaction") {
          return <>{record.transaction.item.nama_event}</>;
        } else if (text === "App\\Models\\BillLink") {
          return <>{record.transaction.item.name}</>;
        } else if (text === "App\\Models\\Transaction") {
          return <>{record.transaction.item[0].nama}</>;
        } else if (text === "App\\Models\\SupportTransaction") {
          return <>{record.transaction.item.username}</>;
        } else {
          return <>{record.transaction.name}</>;
        }
      },
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      key: "nominal",
      render: (text, record) => {
        if (text > 0) {
          return (
            <p className="text-green-600">
              Rp{numeral(record.nominal).format("0,0")}
            </p>
          );
        } else {
          return (
            <p className="text-red-600">
              Rp{numeral(record.nominal).format("0,0")}
            </p>
          );
        }
      },
      sorter: true,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return (
          <p className="text-blue-600">
            Rp{" "}
            {numeral(
              record.transaction.total_plus_admin
                ? record.transaction.total_plus_admin
                : record.transaction.total
            ).format("0,0")}
          </p>
        );
      },
    },
    {
      title: "Metode Pembayaran",
      dataIndex: "transaction.nama_bank",
      key: "transaction.nama_bank",
      render: (text, record) => <>{record.transaction.nama_bank}</>,
    },
    {
      title: "Admin Channel",
      dataIndex: "transaction.admin_channel",
      key: "transaction.admin_channel",
      render: (text, record) => (
        <>Rp{numeral(record.transaction.admin_channel).format("0,0")}</>
      ),
    },
    {
      title: "Admin Berbagilink",
      dataIndex: "transaction.admin_berbagi_link",
      key: "transaction.admin_berbagi_link",
      render: (text, record) => {
        if (record.transaction_type === "App\\Models\\SupportTransaction") {
          return (
            <>Rp{numeral(record.transaction.admin_platform).format("0,0")}</>
          );
        } else {
          return (
            <>
              Rp{numeral(record.transaction.admin_berbagi_link).format("0,0")}
            </>
          );
        }
      },
    },
    {
      title: "Dibuat Pada",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => <>{record.created_at}</>,
      sorter: true,
    },
  ];

  const allColumns = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "2rem",
      render: (text, record, index) => record.transaction.invoice,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text, record, index) => record.user_wallet.user.username,
    },
    {
      title: "Tipe Transaksi",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text, record) => {
        if (text === "App\\Models\\BillLink") {
          return <>Penagihan</>;
        } else if (text === "App\\Models\\EventTransaction") {
          return <>Event</>;
        } else if (text === "App\\Models\\Transaction") {
          return <>OnlineStore</>;
        } else if (text === "App\\Models\\SupportTransaction") {
          return <>Support</>;
        } else {
          return <>QRIS</>;
        }
      },
      sorter: true,
    },
    {
      title: "Nama Item",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text, record) => {
        if (text === "App\\Models\\EventTransaction") {
          return <>{record.transaction.item.nama_event}</>;
        } else if (text === "App\\Models\\BillLink") {
          return <>{record.transaction.item.name}</>;
        } else if (text === "App\\Models\\Transaction") {
          return <>{record.transaction.item[0].nama}</>;
        } else if (text === "App\\Models\\SupportTransaction") {
          return <>{record.transaction.item.username}</>;
        } else {
          return <>{record.transaction.name}</>;
        }
      },
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      key: "nominal",
      render: (text, record) => {
        if (text > 0) {
          return (
            <p className="text-green-600">
              Rp{numeral(record.nominal).format("0,0")}
            </p>
          );
        } else {
          return (
            <p className="text-red-600">
              Rp{numeral(record.nominal).format("0,0")}
            </p>
          );
        }
      },
      sorter: true,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return (
          <p className="text-blue-600">
            Rp{" "}
            {numeral(
              record.transaction.total_plus_admin
                ? record.transaction.total_plus_admin
                : record.transaction.total
            ).format("0,0")}
          </p>
        );
      },
    },
    {
      title: "Metode Pembayaran",
      dataIndex: "transaction.nama_bank",
      key: "transaction.nama_bank",
      render: (text, record) => <>{record.transaction.nama_bank}</>,
    },
    {
      title: "Admin Channel",
      dataIndex: "transaction.admin_channel",
      key: "transaction.admin_channel",
      render: (text, record) => (
        <>Rp{numeral(record.transaction.admin_channel).format("0,0")}</>
      ),
    },
    {
      title: "Admin Berbagilink",
      dataIndex: "transaction.admin_berbagi_link",
      key: "transaction.admin_berbagi_link",
      render: (text, record) => {
        if (record.transaction_type === "App\\Models\\SupportTransaction") {
          return (
            <>Rp{numeral(record.transaction.admin_platform).format("0,0")}</>
          );
        } else {
          return (
            <>
              Rp{numeral(record.transaction.admin_berbagi_link).format("0,0")}
            </>
          );
        }
      },
    },
    {
      title: "Dibuat Pada",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => <>{record.created_at}</>,
      sorter: true,
    },
  ];

  const subColumns = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "2rem",
      render: (text, record, index) => record.order_number,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text, record, index) => record.user.username,
    },
    {
      title: "Nama Item",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text, record) => record.product + " " + record.type_period,
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      key: "nominal",
      render: (text, record) => {
        if (text > 0) {
          return (
            <p className="text-green-600">
              Rp{numeral(record.price).format("0,0")}
            </p>
          );
        } else {
          return (
            <p className="text-red-600">
              Rp{numeral(record.price).format("0,0")}
            </p>
          );
        }
      },
      sorter: true,
    },
    {
      title: "Voucher",
      dataIndex: "voucher",
      key: "voucher",
      render: (text, record) => record.voucher_code,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return (
          <p className="text-blue-600">
            Rp {numeral(record.total_price).format("0,0")}
          </p>
        );
      },
    },
    {
      title: "Metode Pembayaran",
      dataIndex: "transaction.nama_bank",
      key: "transaction.nama_bank",
      render: (text, record) => <>{record.payment_method.name}</>,
    },
    {
      title: "Admin Channel",
      dataIndex: "transaction.admin_channel",
      key: "transaction.admin_channel",
      render: (text, record) => (
        <>Rp{numeral(record.admin_fee_channel).format("0,0")}</>
      ),
    },
    {
      title: "Admin Berbagilink",
      dataIndex: "transaction.admin_berbagi_link",
      key: "transaction.admin_berbagi_link",
      render: (text, record) => (
        <>Rp{numeral(record.admin_fee_berbagilink).format("0,0")}</>
      ),
    },
    {
      title: "Dibuat Pada",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => <>{record.created_at}</>,
      sorter: true,
    },
  ];

  const ziswafColumns = [
    {
      title: "Transaction ID",
      dataIndex: "transaction_id",
      key: "transaction_id",
      width: "2rem",
      render: (text, record, index) => record.id,
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      render: (text, record, index) => record.campaign.user.username,
    },
    {
      title: "Nama Item",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text, record) => record.campaign.name,
    },
    {
      title: "Nominal",
      dataIndex: "nominal",
      key: "nominal",
      render: (text, record) => {
        if (text > 0) {
          return (
            <p className="text-green-600">
              Rp{numeral(record.nominal).format("0,0")}
            </p>
          );
        } else {
          return (
            <p className="text-red-600">
              Rp{numeral(record.nominal).format("0,0")}
            </p>
          );
        }
      },
      sorter: true,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return (
          <p className="text-blue-600">
            Rp {numeral(record.total).format("0,0")}
          </p>
        );
      },
    },
    {
      title: "Metode Pembayaran",
      dataIndex: "transaction.nama_bank",
      key: "transaction.nama_bank",
      render: (text, record) => <>{record.ziswaf_payment_account.bank.name}</>,
    },
    {
      title: "Admin Channel",
      dataIndex: "transaction.admin_channel",
      key: "transaction.admin_channel",
      render: (text, record) => (
        <>Rp{numeral(record.admin_channel).format("0,0")}</>
      ),
    },
    {
      title: "Admin Berbagilink",
      dataIndex: "transaction.admin_berbagi_link",
      key: "transaction.admin_berbagi_link",
      render: (text, record) => (
        <>Rp{numeral(record.admin_platform).format("0,0")}</>
      ),
    },
    {
      title: "Dibuat Pada",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => <>{record.created_at}</>,
      sorter: true,
    },
  ];

  const fetchDatatableModel = async (pagination, sorter) => {
    setLoading(true);
    try {
      const response = await axiosConfig.get(
        `${API_ENDPOINT}/customer/transaction-datatable`,
        {
          params: {
            page: pagination.current,
            limit: pagination.pageSize,
            date_from: props.date.date_from.format("YYYY-MM-DD"),
            date_to: props.date.date_to.format("YYYY-MM-DD"),
            model: props.model,
            search,
            ...sorter,
          },
        }
      );
      setData(response.data.data.data);
      setPaginationState({
        current: response.data.data.current_page,
        pageSize: response.data.data.per_page,
        total: response.data.data.total,
      });
    } catch (error) {
      Toast.fire({
        icon: "error",
        title:
          error.response.data?.info ??
          "Terjadi suatu error saat pengambilan data",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filter, sorter) => {
    if (isEmpty(sorter)) {
      fetchDatatableModel(pagination, {});
    } else {
      fetchDatatableModel(pagination, {
        field: sorter.field,
        sort: sorter.order,
      });
    }
  };

  useEffect(() => {
    fetchDatatableModel(paginationState);
  }, [search, props.date]);

  return (
    <div className="py-3 overflow-x-scroll">
      {/* <div className="flex items-end justify-end mt-3 mb-3">
        <Search
          placeholder="Masukkan pencarian"
          className="max-w-xxs"
          allowClear
          enterButton="Cari"
          onSearch={props.onSearch}
        />
      </div> */}
      <Table
        columns={
          props.model == "all" || props.model == "pembayaran"
            ? allColumns
            : props.model == "berlangganan" ? subColumns : props.model == "donasi" ? ziswafColumns : columns
        }
        dataSource={data}
        pagination={paginationState}
        loading={loading}
        onChange={handleTableChange}
        rowKey={(record) => record.id}
      />
    </div>
  );
}

export default TableCustomer;
