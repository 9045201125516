import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
	const authData = JSON.parse(localStorage.getItem('auth_data'));

	if (isEmpty(authData) || Cookies.get('token') === undefined) {
		return <Navigate to="/login" replace />;
	}
	return children ?? <Outlet />;
};

export default ProtectedRoute;
